@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root, html {
  --dark: #111111;
  --light: #ffffff;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
  background-color: transparent;
  overflow: hidden;
}

h1 {
  color: var(--light);
  font-family: 'Inter', sans-serif;
  font-weight: 100;
}
